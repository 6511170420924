import React from 'react';
import { TimeIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  CircularProgressProps,
  IconProps,
  Flex,
  CircularProgress,
  Icon,
  Text,
} from '@chakra-ui/react';

export enum TransactionState {
  Create,
  Pending,
  Processing,
  Complete,
  Failed,
}

export interface TransactionStateTrackerProps {
  transactionState: TransactionState;
  progress: CircularProgressProps;
  icon: IconProps;
  stateHelperText: StateHelperText;
}

export interface StateHelperText {
  value: string;
  isError?: boolean;
}

export const TransactionStateTracker = React.memo(
  function TransactionStateTracker({
    transactionState,
    progress,
    icon,
    stateHelperText,
  }: TransactionStateTrackerProps) {
    return (
      <Flex direction='column'>
        <Flex justify='center' align='center' h='14em'>
          {transactionState === TransactionState.Pending && (
            <CircularProgress {...progress} />
          )}
          {transactionState === TransactionState.Processing && (
            <Icon as={TimeIcon} {...icon} color='green.500' />
          )}
          {transactionState === TransactionState.Complete && (
            <Icon as={CheckIcon} {...icon} color='green.500' />
          )}
          {transactionState === TransactionState.Failed && (
            <Icon as={CloseIcon} {...icon} color='red.300' />
          )}
        </Flex>
        <Text color={stateHelperText.isError ? 'red.300' : 'green500'}>
          {stateHelperText.value}
        </Text>
      </Flex>
    );
  }
);
