import React, { useState, useCallback, useEffect } from 'react';
import { FormHelperText, Button, VStack, FormControl } from '@chakra-ui/react';
import { User, VerifyUser } from '@bitsacco/types';
import {
  digitizePhone,
  isValidPhone,
  isValidPin,
  isValidOTP,
  isValidNpub,
  truncateText,
} from '../../utils';
import { PinInputGroup, PhoneInputGroup, NpubInputGroup } from '../InputGroups';
import { useApi, useAuth } from '../Providers';

interface PinStackProps {
  pin: string;
  phone: string;
  useNpub: boolean;
  npub: string;
  authError: string;
  setPin: (pin: string) => void;
  setPhone: (phone: string) => void;
  setNpub: (npub: string) => void;
  setAuthError: (error: string) => void;
  signupUser?: () => void;
  recoverUser?: () => void;
}

export const PinStack = React.memo(function PinStack({
  pin,
  phone,
  useNpub,
  npub,
  authError,
  setPin,
  setPhone,
  setNpub,
  setAuthError,
  signupUser,
  recoverUser,
}: PinStackProps) {
  const { bitsacco } = useApi();
  const { login } = useAuth();

  const [otp, setOtp] = useState<string>('');
  const [verifiedOTP, setVerifiedOTP] = useState<boolean>(false);
  const [otpMessage, setOtpMessage] = useState<string>('');

  const [cpin, setCpin] = useState<string>('');

  const verifyUser = useCallback(
    (phone: string | null, npub: string | null, otp?: number) => {
      (async () => {
        try {
          const user = await bitsacco.request<User, VerifyUser>(
            'POST',
            '/user/verify',
            {
              phone,
              npub,
              otp,
            }
          );

          if (!user) {
            return setAuthError(otp ? 'invalid otp' : 'invalid account');
          }

          if (!otp) {
            setOtpMessage(`We have sent an OTP to ${phone}`);
            setAuthError('');
            return;
          }

          if (user.phoneVerified && signupUser) {
            return login(user);
          }

          setVerifiedOTP(true);
          setOtpMessage('');
          setAuthError('');
        } catch (e) {
          setAuthError(`${e}`);
        }
      })();
    },
    [bitsacco, setAuthError, setOtpMessage, setVerifiedOTP, login, signupUser]
  );

  useEffect(() => {
    if ((phone || npub) && recoverUser && !signupUser && !otp && !verifiedOTP) {
      const pNum = phone;

      if (!useNpub && isValidPhone(pNum)) {
        verifyUser(digitizePhone(pNum), null);
      }

      if (useNpub && isValidNpub(npub)) {
        verifyUser(null, npub);
      }
    }
  }, [
    phone,
    npub,
    useNpub,
    otp,
    verifiedOTP,
    verifyUser,
    recoverUser,
    signupUser,
  ]);

  useEffect(() => {
    if (isValidPin(pin) && isValidPin(cpin) && pin !== cpin) {
      setAuthError('pins do not match');
    } else {
      setAuthError('');
    }
  }, [pin, cpin, setAuthError]);

  const showCreateNewPin = useCallback(() => {
    const createPinStack = (
      <>
        <FormHelperText mt={'5'} mb={'2'}>
          Set New Pin
        </FormHelperText>
        <PinInputGroup
          pin={pin}
          setPin={(pin: string) => {
            setAuthError('');
            setPin(pin);
          }}
        />
        <FormHelperText mt={'5'} mb={'2'}>
          Confirm New Pin
        </FormHelperText>
        <PinInputGroup
          pin={cpin}
          setPin={(cpin: string) => {
            setAuthError('');
            setCpin(cpin);
          }}
        />
      </>
    );

    if (recoverUser && verifiedOTP) {
      return createPinStack;
    }

    if (
      signupUser &&
      (isValidPhone(phone) || isValidNpub(npub)) &&
      !otp &&
      !verifiedOTP &&
      !otpMessage
    ) {
      return createPinStack;
    }

    return <></>;
  }, [
    phone,
    npub,
    pin,
    cpin,
    otp,
    verifiedOTP,
    otpMessage,
    setPin,
    setCpin,
    setAuthError,
    recoverUser,
    signupUser,
  ]);

  const showVerifyOTP = useCallback(() => {
    return otpMessage && !verifiedOTP ? (
      <>
        <FormHelperText mt={'5'} mb={'2'}>
          Enter OTP
        </FormHelperText>
        <PinInputGroup
          pin={otp}
          setPin={(otp: string) => {
            setAuthError('');
            setOtp(otp);
          }}
        />
      </>
    ) : (
      <></>
    );
  }, [verifiedOTP, otpMessage, otp, setAuthError, setOtp]);

  const showActionButtons = useCallback(() => {
    if (otp && isValidOTP(otp) && !verifiedOTP) {
      return (
        <Button
          variant='solid'
          colorScheme='teal'
          onClick={() => {
            if (useNpub && isValidNpub(npub)) {
              return verifyUser(null, npub, Number(otp));
            }

            verifyUser(digitizePhone(phone), null, Number(otp));
          }}
          isDisabled={!phone && !npub}
          w='100%'
        >
          Verify
        </Button>
      );
    }

    if (recoverUser && isValidPin(pin) && isValidPin(cpin) && !authError) {
      return (
        <Button
          variant='solid'
          colorScheme='teal'
          onClick={() => {
            recoverUser();
            setAuthError('');
          }}
          isDisabled={!phone && !npub}
          w='100%'
        >
          Continue
        </Button>
      );
    }

    if (signupUser && isValidPin(pin) && isValidPin(cpin) && !authError) {
      return (
        <Button
          variant='solid'
          colorScheme='teal'
          onClick={() => {
            signupUser();
            setOtpMessage(
              `We have sent an OTP to ${phone || truncateText(npub, 5, 5)}`
            );
            setAuthError('');
          }}
          isDisabled={!phone && !npub}
          w='100%'
        >
          Continue
        </Button>
      );
    }

    return <></>;
  }, [
    verifiedOTP,
    phone,
    npub,
    useNpub,
    pin,
    cpin,
    otp,
    verifyUser,
    setOtpMessage,
    setAuthError,
    signupUser,
    recoverUser,
    authError,
  ]);

  return (
    <VStack spacing={5}>
      <FormControl>
        {useNpub ? (
          <NpubInputGroup npub={npub} setNpub={setNpub} />
        ) : (
          <PhoneInputGroup phone={phone} setPhone={setPhone} />
        )}
        {otpMessage && <FormHelperText>{otpMessage}</FormHelperText>}
        {showVerifyOTP()}
        {showCreateNewPin()}
        {authError && (
          <FormHelperText color='red.300' maxW={'350px'}>
            {authError}
          </FormHelperText>
        )}
      </FormControl>
      {showActionButtons()}
    </VStack>
  );
});
