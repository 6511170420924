import { Currency } from './fx';

interface MpesaTransactionBase {
  /**
   * Swap initiator reference to the account this transaction is associated with.
   */
  ref: string;
  /**
   * The amount transacted.
   */
  amount: number;
}

export interface MpesaTransaction extends MpesaTransactionBase {
  /**
   * Unique identifier for the transaction.
   */
  id: string;
  /**
   * The currency of the transaction.
   * One of: `Currency`
   */
  currency: Currency;
  /**
   * The type of transaction.
   * One of: `MpesaTransactionType`
   */
  type: MpesaTransactionType;
  /**
   * Outcome of the transaction
   * One of: `MpesaTractactionState`
   */
  state: MpesaTractactionState;
}

export enum MpesaTransactionType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
}

export interface RequestMpesa extends MpesaTransactionBase {
  /**
   * The phone number associated with the M-Pesa account.
   */
  phone: string;
  /**
   * The Lightning Network invoice or payment request.
   */
  lightning: string;
}

export enum MpesaTractactionState {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Retry = 'RETRY',
  Failed = 'FAILED',
  Complete = 'COMPLETE',
}

export interface MpesaInvoice {
  invoice_id: string;
  state: MpesaTractactionState;
  charges: string;
  net_amount: string;
  currency: string;
  value: string;
  account: string;
  api_ref: string;
  retry_count: number;
  created_at: string;
  updated_at: string;
}

export interface MpesaTransactionUpdate extends MpesaInvoice {
  failed_reason: string | null;
  failed_code: string | null;
  challenge: string;
}

export interface RequestMpesaResponse {
  id: string;
  invoice: MpesaInvoice;
  refundable: boolean;
  created_at: string;
  updated_at: string;
}

export const MPESA_TRANSACTION_UPDATE_CHALLENGE = 'BITSACCO';

export interface LightningFromMpesa {
  /**
   * lightning invoice to pay.
   */
  lightning: string;
  /**
   * incoming Mpesa transaction ID.
   */
  mpesa: string;
  /**
   * References the user who made the transaction.
   * userId is the users public key.
   */
  userId: string;
  /**
   * References the account this transaction is associated with.
   * Example:
   *  - `chama:${chamaId}`
   *  - `sacco:${saccoId}`
   */
  account: string;
  /**
   * state and progress of the swap
   */
  state: LightningFromMpesaState;
}

export enum LightningFromMpesaState {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Failed = 'FAILED',
  Complete = 'COMPLETE',
}
