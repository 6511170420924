import React, { useEffect, useState } from 'react';
import { AvatarProps, Avatar } from '@chakra-ui/react';
import { User } from '@bitsacco/types';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../enums/QueryKeys';
import fetcher from '../helpers/fetcher';
import { BS_API_URL } from '../configs';

interface HeadshotProps extends AvatarProps {
  user: User;
}

export const Headshot = React.memo(function Headshot({
  user,
  ...props
}: HeadshotProps): JSX.Element {
  return (
    <Avatar
      {...props}
      name={user.profile?.name || generateNameFromPhone(user.phone)}
      src={user.profile?.avatar}
    />
  );
});

type FetchHeadshotProps = Omit<HeadshotProps, 'user'> & {
  id: string;
};

export const FetchHeadshot = React.memo(function FetchHeadshot({
  id,
  ...props
}: FetchHeadshotProps): JSX.Element {
  const [user, setUser] = useState<User | undefined>();

  const { data: fetchedUser } = useQuery<User, Error>({
    queryKey: [QueryKeys.FIND_USER],
    queryFn: async () => {
      return await fetcher<User, Error>(`${BS_API_URL}/user/find/id/${id}`);
    },
    enabled: !!id,
  });

  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
  }, [setUser]);

  return user ? (
    <Headshot user={user} {...props} />
  ) : (
    <Avatar {...props} bg='teal.500' />
  );
});

function generateNameFromPhone(phoneNumber: string | null): string {
  if (phoneNumber === null) {
    return generateRandomName();
  }

  const digits = phoneNumber.replace(/\D/g, '');
  const lastSix = digits.slice(-6);

  const letterMap = 'ABCDEFGHIJ';
  const letters = lastSix
    .split('')
    .map((digit) => letterMap[parseInt(digit)])
    .join('');
  return `Member-${letters}`;
}

function generateRandomName(): string {
  const letterMap = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomLetters = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * letterMap.length);
    randomLetters += letterMap[randomIndex];
  }
  return `Member-${randomLetters}`;
}
