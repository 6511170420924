import { v4 as uuidv4 } from 'uuid';

interface Message {
  id: string;
  text: string;
  createdAt: Date;
  uid?: string;
  displayName?: string;
  chamaId: string;
}

function adminMessages(): Message[] {
  const adminId = uuidv4();
  return [
    {
      id: uuidv4(),
      text: 'You made it to the chama chat! 🎉',
      createdAt: new Date(),
      uid: adminId,
      displayName: 'Bitsacco',
      chamaId: 'global',
    },
    {
      id: uuidv4(),
      text: 'Here you will be able to chat with other members of your chama.',
      createdAt: new Date(),
      uid: adminId,
      displayName: 'Bitsacco',
      chamaId: 'global',
    },
    {
      id: uuidv4(),
      text: 'You can also tag @bitsacco to send us feedback through this chat.',
      createdAt: new Date(),
      uid: adminId,
      displayName: 'Bitsacco',
      chamaId: 'global',
    },
    {
      id: uuidv4(),
      text: 'This feature is still under development, but feel free to send a test message below...',
      createdAt: new Date(),
      uid: adminId,
      displayName: 'Bitsacco',
      chamaId: 'global',
    },
  ];
}

// This is a mock implementation. In a real app, this would interact with your backend.
const messages: Message[] = adminMessages();

export function subscribeToMessages(
  chamaId: string,
  observer: {
    next: (messages: Message[]) => void;
    error: (error: Error) => void;
  }
) {
  // Simulate real-time updates
  const interval = setInterval(() => {
    observer.next(
      [...messages].filter((m) => [chamaId, 'global'].includes(m.chamaId))
    );
  }, 1000);

  return () => clearInterval(interval);
}

export async function sendMessage(
  chamaId: string,
  message: Omit<Message, 'id'>
) {
  // Simulate sending a message
  console.log(`Senging message to ${chamaId}`);
  const newMessage: Message = { ...message, id: Date.now().toString() };
  messages.push(newMessage);
  return newMessage;
}
