import React from 'react';
import {
  Flex,
  Text,
  IconButton,
  useTheme,
  useColorMode,
  useColorModeValue,
  Link,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { FaTwitter, FaLinkedin } from 'react-icons/fa6';
import { Feedback } from './Feedback';

export const Footer = React.memo(function Footer() {
  const { colorMode, toggleColorMode } = useColorMode();
  const theme = useTheme();

  const bg = useColorModeValue(theme.colors.gray[50], theme.colors.gray[700]);

  const {
    isOpen: isOpenFeedback,
    onOpen: onOpenFeedback,
    onClose: onCloseFeedback,
  } = useDisclosure();

  return (
    <>
      <Flex
        direction={{ base: 'column-reverse', sm: 'row' }}
        align='center'
        justify='space-between'
        w='100vw'
        maxW='1440px'
        minH='100px'
        py='3'
        px={{ base: '12px', md: '20px', lg: '40px', xl: '100px' }}
        bg={bg}
        gap={{ base: 4, md: 0 }}
      >
        <Flex flexDirection='column' justifyContent='center' flexGrow={1}>
          <Text fontSize='xl' fontWeight='semibold'>
            © BITSACCO
          </Text>
          <Link
            href='https://mempool.space/block/0000000000000000000320283a032748cef8227873ff4872689bf23f1cda83a5'
            isExternal
            fontSize='xl'
            display={'flex'}
            alignItems={{ base: 'center', md: 'flex-start' }}
            justifyContent={{ base: 'center', md: 'flex-start' }}
            color='teal'
          >
            EST. 840,000
          </Link>
        </Flex>
        <Flex
          gap={2}
          direction={{ base: 'column', sm: 'column' }}
          alignItems={{ base: 'center', md: 'flex-end' }}
          justifyContent={{ base: 'center', md: 'flex-end' }}
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexGrow={1}
        >
          <Button
            variant='outline'
            colorScheme='teal'
            minW={'134px'}
            onClick={onOpenFeedback}
          >
            FEEDBACK
          </Button>
          <Flex
            gap={2}
            direction='row'
            alignItems={{ base: 'center', md: 'flex-end' }}
            justifyContent={{ base: 'center', md: 'flex-end' }}
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          >
            <IconButton
              as='a'
              target='_blank'
              rel='noopener noreferrer'
              href='https://x.com/bitsacco'
              variant='outline'
              aria-label='Toggle theme'
              icon={<FaTwitter />}
            />
            <IconButton
              as='a'
              target='_blank'
              rel='noopener noreferrer'
              href='https://linkedin.com/company/bitsacco/'
              variant='outline'
              aria-label='Toggle theme'
              icon={<FaLinkedin />}
            />
            <IconButton
              variant='outline'
              aria-label='Toggle theme'
              icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
              onClick={toggleColorMode}
            />
          </Flex>
        </Flex>
      </Flex>
      <Feedback
        isOpenFeedback={isOpenFeedback}
        onCloseFeedback={onCloseFeedback}
      />
    </>
  );
});
