export enum Currency {
  BTC = 'BTC',
  KES = 'KES',
}

export interface FxRate {
  id: string;
  satrate: number;
  kesrate: number;
  timestamp: Date;
}

export const FXID = 'kesbtc';
