import React, { useCallback } from 'react';
import {
  FormHelperText,
  VStack,
  Card,
  CardHeader,
  CardBody,
  FormControl,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { User, LoginUser } from '@bitsacco/types';
import {
  isValidPhone,
  isValidPin,
  digitizePhone,
  isValidNpub,
} from '../../utils';
import { PinInputGroup, PhoneInputGroup, NpubInputGroup } from '../InputGroups';
import { useApi, useAuth } from '../Providers';
import { AuthStackProps, AuthExperience } from './types';

export const LoginStack = ({
  pin,
  phone,
  useNpub,
  npub,
  authError,
  setPin,
  setPhone,
  setNpub,
  setAuthError,
  setAuthExperience,
  setUseNpub,
}: AuthStackProps) => {
  const { bitsacco } = useApi();
  const { login } = useAuth();

  const loginUser = useCallback(() => {
    if (!useNpub && !isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (useNpub && !isValidNpub(npub)) {
      setAuthError('invalid nostr identity');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, LoginUser>(
          'POST',
          '/user/login',
          {
            phone: useNpub ? null : digitizePhone(phone),
            npub,
            pin,
          }
        );

        if (user) {
          return login(user);
        }

        throw `login error`;
      } catch (e) {
        setAuthError(`${e}`);
        setPin('');
      }
    })();
  }, [bitsacco, phone, npub, useNpub, pin, login, setAuthError, setPin]);

  const showEnterPin = useCallback(() => {
    if (
      (!useNpub && phone && isValidPhone(phone)) ||
      (useNpub && npub && isValidNpub(npub))
    ) {
      return (
        <>
          <FormHelperText mt={'5'} mb={'2'}>
            Enter Pin
          </FormHelperText>
          <PinInputGroup
            pin={pin}
            setPin={(pin: string) => {
              setAuthError('');
              setPin(pin);
            }}
          />
        </>
      );
    }

    return <></>;
  }, [phone, npub, useNpub, pin, setPin, setAuthError]);

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            LOGIN
          </Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4}>
            <FormControl>
              {useNpub ? (
                <NpubInputGroup npub={npub} setNpub={setNpub} />
              ) : (
                <PhoneInputGroup
                  phone={phone}
                  setPhone={(phone: string) => {
                    setAuthError('');
                    setPhone(phone);
                  }}
                />
              )}
              {showEnterPin()}
              {authError && (
                <FormHelperText color='red.300'>{authError}</FormHelperText>
              )}
            </FormControl>
            <Button
              variant='solid'
              colorScheme='teal'
              onClick={loginUser}
              isDisabled={!isValidPin(pin) || !!authError}
              w='100%'
            >
              Continue
            </Button>
            <Button variant='link' onClick={() => setUseNpub(!useNpub)}>
              use {useNpub ? 'phone' : 'nostr'}
            </Button>
          </VStack>
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='ghost'
          onClick={() => setAuthExperience(AuthExperience.Signup)}
        >
          SIGNUP
        </Button>
        <Button
          variant='ghost'
          onClick={() => setAuthExperience(AuthExperience.Recover)}
        >
          RECOVER
        </Button>
      </Flex>
    </VStack>
  );
};
