import { SESSION_COOKIE_KEY } from '@bitsacco/types';

export const SESSION_STORAGE_KEY = 'bitsacco';
export const SESSION_USER_KEY = 'SessionUser';

interface BitsaccoSessionData {
  [SESSION_COOKIE_KEY]?: string;
  [SESSION_USER_KEY]?: string;
}

const loadSessionData = (): BitsaccoSessionData => {
  const sessionData = sessionStorage.getItem(SESSION_STORAGE_KEY);

  if (!sessionData) {
    return {};
  }

  return JSON.parse(sessionData);
};

export const getSessionValue = (key: keyof BitsaccoSessionData): string => {
  const sessionData = loadSessionData();
  return sessionData[key] || '';
};

export const setSessionValue = (
  key: keyof BitsaccoSessionData,
  value: string
): void => {
  const sessionData = loadSessionData();

  sessionData[key] = value;
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(sessionData));
};

export const removeSessionValue = (key: keyof BitsaccoSessionData): void => {
  const sessionData = loadSessionData();

  delete sessionData[key];
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(sessionData));
};

export const LOCAL_STORAGE_KEY = 'bitsacco';
export const BITSACCCO_NOSTR_PRIVATE_KEY = 'nostr-privkey';

interface BitsaccoLocalData {
  [BITSACCCO_NOSTR_PRIVATE_KEY]?: string;
}

const loadLocalData = (): BitsaccoLocalData => {
  const localData = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!localData) {
    return {};
  }

  return JSON.parse(localData);
};

export const getLocalValue = (key: keyof BitsaccoLocalData): string => {
  const localData = loadLocalData();
  return localData[key] || '';
};

export const setLocalValue = (
  key: keyof BitsaccoLocalData,
  value: string
): void => {
  const localData = loadLocalData();

  localData[key] = value;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localData));
};

export const removeLocalValue = (key: keyof BitsaccoLocalData): void => {
  const localData = loadLocalData();

  delete localData[key];
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localData));
};
