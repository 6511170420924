import React, { useCallback, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Chama, InviteMembers } from '@bitsacco/types';
import { TOAST_TIMEOUT_MS } from '../../configs';

import { TemplateModal } from '../TemplateModal';
import { useApi } from '../Providers';
import { TagPhoneInputGroup } from '../InputGroups';
import { digitizePhone } from '../../utils';

interface InviteModalProps {
  chama: Chama;
  isOpen: boolean;
  onClose: () => void;
}

export const InviteModal = React.memo(function InviteModal({
  chama,
  isOpen,
  onClose,
}: InviteModalProps): JSX.Element {
  const { bitsacco } = useApi();
  const [invites, setInvites] = useState<string[]>([]);
  const toast = useToast();

  const closeModal = useCallback(() => {
    setInvites([]);
    onClose();
  }, [setInvites, onClose]);

  const sendChamaInvites = useCallback(() => {
    const chamaInvites: InviteMembers = {
      id: chama.id,
      users: invites.map((phone) => digitizePhone(phone)),
    };

    (async () => {
      try {
        await bitsacco.request<void, InviteMembers>(
          'POST',
          '/chama/member/invite',
          chamaInvites
        );

        console.log('sent chama invites');
        toast({
          title: 'Success',
          description: 'Sent chama invites',
          status: 'success',
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
        return closeModal();
      } catch (e) {
        console.error(e);
        toast({
          title: 'Error',
          description: 'Failed to create chama',
          status: 'error',
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      }
    })();
  }, [bitsacco, chama, invites, toast, closeModal]);

  const getModalBody = useCallback(() => {
    return (
      <Flex flexDirection='column' gap='5'>
        <Text fontSize='md' textAlign='center' color='teal.500'>
          Search and add people in your chama. We will send a text message to
          each number invited
        </Text>
        <FormControl>
          <TagPhoneInputGroup phoneTags={invites} setPhoneTags={setInvites} />
        </FormControl>
      </Flex>
    );
  }, [invites, setInvites]);

  const getModalActions = useCallback(() => {
    return (
      <>
        <Button
          onClick={sendChamaInvites}
          variant='solid'
          colorScheme='teal'
          isDisabled={invites.length < 1}
        >
          Send Invites
        </Button>
        <Button
          size='lg'
          onClick={closeModal}
          variant='ghost'
          colorScheme='red'
        >
          Cancel
        </Button>
      </>
    );
  }, [invites, sendChamaInvites, closeModal]);

  const getModalFooter = useCallback(() => {
    return (
      <ButtonGroup
        w='100%'
        display='flex'
        flexDirection='row'
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        justifyContent='space-between'
        spacing={{ base: '0', lg: '4' }}
        gap={'2'}
      >
        {getModalActions()}
      </ButtonGroup>
    );
  }, [getModalActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={closeModal}
      header={<Text>{`Invite members to ${chama.name}`}</Text>}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
