import React, { useState, useCallback } from 'react';
import {
  Button,
  Flex,
  FormControl,
  Box,
  FormHelperText,
  ButtonGroup,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  Chama,
  ChamaMemberRole,
  ChamaTx,
  ChamaTxPath,
  ChamaTxState,
  ChamaTxType,
  CreateChamaTx,
  Currency,
  User,
} from '@bitsacco/types';
import { AmountInputGroup } from '../InputGroups';
import { useFx } from '../Providers';
import { TemplateModal } from '../TemplateModal';
import { getGroupBalanceMsats } from '../../services';
import { BS_API_URL, TOAST_TIMEOUT_MS } from '../../configs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import fetcher from '../../helpers/fetcher';
import { QueryKeys } from '../../enums/QueryKeys';

interface RequestWithdrawModalProps {
  chama: Chama;
  user: User;
  isAdmin: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const RequestWithdrawModal = React.memo(function RequestWithdrawModal({
  chama,
  user,
  isAdmin,
  isOpen,
  onClose,
}: RequestWithdrawModalProps): JSX.Element {
  const queryClient = useQueryClient();
  const { milliSatsToKes } = useFx();
  const toast = useToast();
  const [amount, setAmount] = useState<number>(0);
  const [requestError, setRequestError] = useState<string>('');

  const closeModal = useCallback(() => {
    setAmount(0);
    setRequestError('');
    onClose();
  }, [onClose, setAmount, setRequestError]);

  const requestWithdrawalMutation = useMutation<ChamaTx, Error, CreateChamaTx>({
    mutationFn: (payload: CreateChamaTx) =>
      fetcher(`${BS_API_URL}/chama/tx/create`, 'POST', payload),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [QueryKeys.CHAMA_TX],
      });
      toast({
        title: 'Success',
        description: `Sent withdrawal request.`,
        status: 'success',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
      closeModal();
    },
    onError: (e) => {
      setRequestError(`${e}`);
      toast({
        title: 'Error',
        description: `Failed to request withdrawal.`,
        status: 'error',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
  });

  const requestWithdrawal = useCallback(() => {
    requestWithdrawalMutation.mutate({
      type: ChamaTxType.Withdrawal,
      path: ChamaTxPath.Lightning,
      amount,
      lightning: { invoice: '', operationId: '' },
      meta: {
        chama: chama.id,
        user: user.id,
        description: `Withdrawal of ${amount} ${Currency.KES}`,
        timestamp: new Date(),
      },
      approvals: isAdmin ? [{ id: user.id, role: ChamaMemberRole.Admin }] : [],
      state: ChamaTxState.Pending,
    });
  }, [chama, user, amount, isAdmin, requestWithdrawalMutation]);

  const updateAmount = useCallback(
    (amount: number) => {
      const available = getGroupBalanceMsats(chama.members);
      const fx = milliSatsToKes(available);
      if (fx.r === Currency.KES && amount > fx.n) {
        setAmount(amount);
        setRequestError(`The maximum you can withdraw is ${fx.n}`);
      } else {
        setAmount(amount);
        setRequestError('');
      }
    },
    [chama, setAmount, setRequestError, milliSatsToKes]
  );

  const getModalHeader = useCallback(() => {
    return <Text>Request withdrawal from {chama.name}</Text>;
  }, [chama]);

  const getModalBody = useCallback(() => {
    return (
      <Flex flexDirection='column' gap='5' h='100%' justify='center'>
        <FormControl>
          <Box pb='5'>
            <AmountInputGroup
              amount={amount}
              setAmount={updateAmount}
              getFormHelperText={(amountError?: string) => {
                return amountError ? (
                  <FormHelperText color='red.300'>{amountError}</FormHelperText>
                ) : (
                  <></>
                );
              }}
            />
          </Box>
          {requestError ? (
            <FormHelperText color='red.300'>{requestError}</FormHelperText>
          ) : (
            <FormHelperText>
              we will send a withdrawal request to all the chama admins
            </FormHelperText>
          )}
        </FormControl>
      </Flex>
    );
  }, [amount, requestError, updateAmount]);

  const getModalFooter = useCallback(() => {
    return (
      <ButtonGroup
        w='100%'
        display='flex'
        flexDirection='row'
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        justifyContent='space-between'
        spacing={{ base: '0', lg: '4' }}
        gap={'2'}
      >
        <Button
          variant='solid'
          colorScheme='green'
          isDisabled={amount < 1 || !!requestError}
          onClick={requestWithdrawal}
        >
          Request Withdrawal
        </Button>
        <Button onClick={closeModal} variant='outline' colorScheme='red'>
          Cancel
        </Button>
      </ButtonGroup>
    );
  }, [amount, requestError, closeModal, requestWithdrawal]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
