export enum AuthExperience {
  Resume,
  Login,
  Signup,
  Recover,
}

export interface AuthStackProps {
  pin: string;
  phone: string;
  useNpub: boolean;
  npub: string;
  authError: string;
  setPin: (pin: string) => void;
  setPhone: (phone: string) => void;
  setNpub: (npub: string) => void;
  setUseNpub: (useNpub: boolean) => void;
  setAuthError: (error: string) => void;
  setAuthExperience: (exp: AuthExperience) => void;
}

export interface NostrAuthStackProps {
  pin: string;
  authError: string;
  npub: string;
  setPin: (pin: string) => void;
  setNpub: (npub: string) => void;
  setAuthError: (error: string) => void;
  setAuthExperience: (exp: AuthExperience) => void;
}
