import React, { useCallback, useState } from 'react';
import { Button, ButtonGroup, Text, Flex, useToast } from '@chakra-ui/react';
import { RecoverUser, User } from '@bitsacco/types';
import { BS_API_URL, TOAST_TIMEOUT_MS } from '../../configs';
import { digitizePhone, isValidPhone, isValidPin } from '../../utils';
import { TemplateModal } from '../TemplateModal';
import { PinStack } from '../auth';
import { useMutation } from '@tanstack/react-query';
import fetcher from '../../helpers/fetcher';

interface ResetPinModalProps {
  phone: string;
  useNpub: boolean;
  npub: string;
  isOpen: boolean;
  onClose: () => void;
}

enum ModalView {
  AlertReset,
  ResetPin,
}

export const ResetPinModal = React.memo(function ResetPinModal({
  phone,
  useNpub,
  npub,
  isOpen,
  onClose,
}: ResetPinModalProps): JSX.Element {
  const [pin, setPin] = useState<string>('');

  const [authError, setAuthError] = useState<string>('');
  const [modalView, setModalView] = useState<ModalView>(ModalView.AlertReset);

  const toast = useToast();

  const setPhone = (phone: string) => console.log(phone);
  const setNpub = (npub: string) => console.log(npub);

  const closeModal = useCallback(() => {
    setPin('');
    setModalView(ModalView.AlertReset);
    setAuthError('');
    onClose();
  }, [onClose, setPin, setModalView, setAuthError]);

  const recoverUserMutation = useMutation<User, Error, RecoverUser>({
    mutationFn: (payload: RecoverUser) =>
      fetcher(`${BS_API_URL}/user/recover`, 'POST', payload),
    onSuccess: () => {
      toast({
        title: 'Success',
        description: `Updated account info.`,
        status: 'success',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
      closeModal();
    },
    onError: (error) => {
      setAuthError(`${error}`);
      toast({
        title: 'Error',
        description: `${error.message || 'Failed to update account info.'}`,
        status: 'error',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
  });

  const recoverUser = useCallback(() => {
    if (!isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    recoverUserMutation.mutate({
      phone: digitizePhone(phone),
      pin,
    });
  }, [phone, pin, recoverUserMutation]);

  const getModalHeader = useCallback(() => {
    switch (modalView) {
      case ModalView.AlertReset:
        return (
          <Flex align='center' justify='center'>
            <Text>Confirm Pin Reset</Text>
          </Flex>
        );
      case ModalView.ResetPin:
        return (
          <Flex align='center' justify='center'>
            <Text>Reset Pin</Text>
          </Flex>
        );
    }
  }, [modalView]);

  const getModalBody = useCallback(() => {
    switch (modalView) {
      case ModalView.AlertReset:
        return (
          <Flex align='center' justify='center'>
            <Text>
              Are you sure you want to{' '}
              <span
                style={{
                  textTransform: 'uppercase',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: 'teal',
                }}
              >
                reset
              </span>{' '}
              the pin securing your account?
            </Text>
          </Flex>
        );
      case ModalView.ResetPin:
        return (
          <Flex align='center' justify='center'>
            <PinStack
              {...{
                pin,
                phone,
                useNpub,
                npub,
                authError,
                setPin,
                setPhone,
                setNpub,
                setAuthError,
                recoverUser,
              }}
            />
          </Flex>
        );
    }
  }, [
    modalView,
    pin,
    phone,
    npub,
    useNpub,
    authError,
    setPin,
    setAuthError,
    recoverUser,
  ]);

  const getModalFooter = useCallback(() => {
    switch (modalView) {
      case ModalView.AlertReset:
        return (
          <ButtonGroup
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Button onClick={closeModal} variant='outline' colorScheme='teal'>
              Cancel
            </Button>
            <Button
              onClick={() => setModalView(ModalView.ResetPin)}
              variant='solid'
              colorScheme='teal'
            >
              Continue
            </Button>
          </ButtonGroup>
        );
      case ModalView.ResetPin:
        return (
          <ButtonGroup
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Button onClick={closeModal} variant='outline' colorScheme='red'>
              Cancel
            </Button>
          </ButtonGroup>
        );
    }
  }, [modalView, setModalView, closeModal]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={closeModal}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
