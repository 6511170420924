import React from 'react';
import {
  Button,
  Flex,
  Text,
  Heading,
  useDisclosure,
  Center,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { Chama } from '@bitsacco/types';
import { ChamaModal, useAuth, ChamaCard } from '../components';
import { digitizePhone } from '../utils';
import { useQuery } from '@tanstack/react-query';
import fetcher from '../helpers/fetcher';
import { BS_API_URL } from '../configs';
import { QueryKeys } from '../enums/QueryKeys';

export const Chamas = React.memo(function Chamas(): JSX.Element {
  const { user } = useAuth();

  const {
    isOpen: showChamaModal,
    onOpen: onOpenChamaModal,
    onClose: onCloseChamaModal,
  } = useDisclosure();

  const {
    data: chamas = [],
    isLoading: fetchLoading,
    error: fetchError,
  } = useQuery<Chama[], Error>({
    queryKey: [QueryKeys.CHAMAS],
    queryFn: async () => {
      const fetchedChamas = await fetcher<Chama[], Error>(
        `${BS_API_URL}/chama/all`
      );
      if (user?.id) {
        return fetchedChamas.filter((chama) =>
          Object.keys(chama.members).includes(digitizePhone(user.id))
        );
      }
      return [];
    },
    enabled: !!user?.id,
  });

  if (fetchLoading)
    return (
      <Center w='100%' pt='5rem'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Center>
    );
  if (fetchError)
    return (
      <Alert status='error' mt='5rem'>
        <AlertIcon />
        There was an error processing your request
      </Alert>
    );

  return (
    <>
      <Flex direction='column' flexGrow={1} basis='100%' gap='5' p='5' pb='20'>
        <Flex
          mt='10'
          p='5'
          gap='4'
          direction='row'
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          align='center'
          justifyItems='center'
          justify='space-between'
        >
          <Flex direction='row' align='center' justify='center'>
            <Heading size='md'>CHAMA SAVINGS</Heading>
            <Text p='3'>|</Text>
            <Text>Manage funds together with friends and family</Text>
          </Flex>
          <Button
            variant='outline'
            colorScheme='green'
            height='35px'
            onClick={onOpenChamaModal}
          >
            + create
          </Button>
        </Flex>
        <Flex direction='column' gap='10'>
          {user &&
            chamas.map((chama, idx) => (
              <ChamaCard key={idx} user={user} chama={chama} />
            ))}
        </Flex>
      </Flex>

      {user && (
        <ChamaModal
          user={user}
          chama={undefined}
          isOpen={showChamaModal}
          onClose={() => onCloseChamaModal()}
        />
      )}
    </>
  );
});
