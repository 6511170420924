import React, { RefAttributes } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

interface RouterLinkProps extends LinkProps, RefAttributes<HTMLAnchorElement> {}

export const RouterLink = React.memo(function RouterLink({
  to,
  children,
  ...props
}: RouterLinkProps): JSX.Element {
  const location = useLocation();
  return (
    <Link
      to={to}
      {...props}
      state={{ from: `${location.pathname}${location.search}` }}
    >
      {children}
    </Link>
  );
});
