import { ChamaMembers } from '@bitsacco/types';

export const getUserBalanceMsats = (
  chamaMembers: ChamaMembers,
  userId: string
) => {
  const member = chamaMembers[userId];

  if (!member) {
    throw new Error('User is not a member of this chama');
  }

  return member.contributions;
};

export const getGroupBalanceMsats = (chamaMembers: ChamaMembers) => {
  return Object.values(chamaMembers).reduce(
    (acc, member) => acc + member.contributions,
    0
  );
};
