import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
  Flex,
  Box,
  FormControl,
  FormHelperText,
  Text,
  Divider,
  AbsoluteCenter,
  useColorModeValue,
} from '@chakra-ui/react';
import { WebLNNode, WebLNProvider } from '@webbtc/webln-types';
import {
  AmountInputGroup,
  InvoiceInputGroup,
  PhoneInputGroup,
} from '../InputGroups';
import { TemplateModal } from '../TemplateModal';
import { useApi, useFx } from '../Providers';
import { TransactionState, TransactionStateTracker } from '../TransactionState';
import { WithdrawClient, WithdrawTarget } from './types';

interface WithdrawModalProps {
  withdrawClient: WithdrawClient;
  withdrawTarget: WithdrawTarget;
  isOpen: boolean;
  onClose: () => void;
  onTransactionComplete: (txid: string, amount: number) => void;
}

export const WithdrawModal = function WithdrawModal({
  withdrawClient,
  withdrawTarget,
  isOpen,
  onClose,
  onTransactionComplete,
}: WithdrawModalProps): JSX.Element {
  const { webln } = useApi();

  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [amount, setAmount] = useState<number>(0);
  const [withdrawState, setWithdrawState] = useState(TransactionState.Create);
  const [showLnWithdraw, setShowLnWithdraw] = useState(false);

  const bg = useColorModeValue(theme.colors.gray[50], theme.colors.gray[700]);

  const closeDialogue = useCallback(() => {
    setWithdrawState(TransactionState.Create);
    onClose();
  }, [onClose]);

  const getExtraWithdrawActions = useCallback(() => {
    if (
      withdrawState === TransactionState.Failed ||
      withdrawState === TransactionState.Complete
    ) {
      return (
        <>
          <Button
            onClick={() => setWithdrawState(TransactionState.Create)}
            variant='outline'
            colorScheme='teal'
          >
            Start Over
          </Button>
          <Button onClick={closeDialogue} variant='outline' colorScheme='red'>
            Close
          </Button>
        </>
      );
    }

    return (
      <Button onClick={closeDialogue} variant='outline' colorScheme='red'>
        Cancel
      </Button>
    );
  }, [withdrawState, closeDialogue]);

  const getModalHeader = useCallback(() => {
    return <Text>Withdraw funds from {withdrawTarget.target.name}</Text>;
  }, [withdrawTarget]);

  const getModalBody = useCallback(() => {
    return (
      <Tabs
        isFitted
        colorScheme={theme.colors.teal[50]}
        onChange={(index) => setTabIndex(index)}
        defaultIndex={tabIndex}
      >
        <TabList mb='1em'>
          <Tab>Use Lightning</Tab>
          <Tab>Use Mpesa</Tab>
        </TabList>
        <TabPanels minH='16em'>
          <TabPanel>
            <Flex flexDirection='column' gap='5' h='100%' justify='center'>
              {webln && !showLnWithdraw ? (
                <WebLnWithdraw
                  webln={webln}
                  amount={amount}
                  updateAmount={setAmount}
                  withdrawTarget={withdrawTarget}
                  withdrawClient={withdrawClient}
                  withdrawState={withdrawState}
                  updateWithdrawState={setWithdrawState}
                  onTransactionComplete={onTransactionComplete}
                />
              ) : (
                <LightningWithdraw
                  withdrawTarget={withdrawTarget}
                  withdrawClient={withdrawClient}
                  withdrawState={withdrawState}
                  updateWithdrawState={setWithdrawState}
                  onTransactionComplete={onTransactionComplete}
                />
              )}
              <Box position='relative' padding='5'>
                <Divider colorScheme='green' size='md' />
                <AbsoluteCenter bg={bg} px='4'>
                  <Button
                    variant='outline'
                    colorScheme='green'
                    height={'35px'}
                    onClick={() => setShowLnWithdraw(!showLnWithdraw)}
                  >
                    {webln && !showLnWithdraw ? 'paste invoice' : 'use wallet'}
                  </Button>
                </AbsoluteCenter>
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex
              flexDirection='column'
              gap='5'
              h='100%'
              justify='center'
              align='center'
            >
              <MpesaWithdraw
                amount={amount}
                updateAmount={setAmount}
                withdrawTarget={withdrawTarget}
                withdrawClient={withdrawClient}
                withdrawState={withdrawState}
                updateWithdrawState={setWithdrawState}
                onTransactionComplete={onTransactionComplete}
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }, [
    webln,
    amount,
    withdrawTarget,
    withdrawClient,
    withdrawState,
    showLnWithdraw,
    setAmount,
    setWithdrawState,
    onTransactionComplete,
    theme,
    tabIndex,
    setTabIndex,
    setShowLnWithdraw,
    bg,
  ]);

  const getModalFooter = useCallback(() => {
    return <ButtonGroup spacing='2'>{getExtraWithdrawActions()}</ButtonGroup>;
  }, [getExtraWithdrawActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
};

interface WithdrawExpereinceProps {
  withdrawClient: WithdrawClient;
  withdrawTarget: WithdrawTarget;
  withdrawState: TransactionState;
  updateWithdrawState: (state: TransactionState) => void;
  onTransactionComplete: (txid: string, amount: number) => void;
}

const MpesaWithdraw = React.memo(function MpesaWithdraw({
  amount,
  updateAmount,
  withdrawTarget,
  updateWithdrawState,
  onTransactionComplete,
}: WithdrawExpereinceProps & {
  amount: number;
  updateAmount: (amount: number) => void;
}) {
  const [phone, setPhone] = useState<string>(withdrawTarget.user.phone || '');

  const [withdrawError, setWithdrawError] = useState<string>();

  const withdrawToMpesa = useCallback(() => {
    if (!amount || !phone) {
      return;
    }

    (async () => {
      try {
        // TODO: Issue #18: Implement Mpesa Withdrawal
        onTransactionComplete('txid', amount);
        return;
      } catch (e) {
        updateWithdrawState(TransactionState.Failed);
        setWithdrawError(`Withdrawal Failed: ${e}`);
      }
    })();
  }, [
    phone,
    amount,
    updateWithdrawState,
    onTransactionComplete,
    setWithdrawError,
  ]);

  const getFormHelperText = useCallback(() => {
    if (withdrawError) {
      return <FormHelperText color='red.300'>{withdrawError}</FormHelperText>;
    }

    return (
      <FormHelperText>
        we will send an mpesa payment to the phone number you enter above
      </FormHelperText>
    );
  }, [withdrawError]);

  return (
    <Flex flexDirection='column' gap='5' h='100%' justify='center'>
      <FormControl>
        <Box pb='5'>
          <AmountInputGroup
            amount={amount}
            setAmount={updateAmount}
            getFormHelperText={(amountError?: string) => {
              return amountError ? (
                <FormHelperText color='red.300'>{amountError}</FormHelperText>
              ) : (
                <></>
              );
            }}
          />
        </Box>
        <Box pb='2'>
          <PhoneInputGroup phone={phone} setPhone={setPhone} />
        </Box>
        {getFormHelperText()}
      </FormControl>
      <Button
        onClick={withdrawToMpesa}
        variant='solid'
        colorScheme='green'
        isDisabled
      >
        Withdraw Funds
      </Button>
    </Flex>
  );
});

const WebLnWithdraw = React.memo(function WebLnWithdraw({
  webln,
  amount,
  updateAmount,
  withdrawTarget,
  withdrawClient,
  withdrawState,
  updateWithdrawState,
  onTransactionComplete,
}: WithdrawExpereinceProps & {
  webln: WebLNProvider;
  amount: number;
  updateAmount: (amount: number) => void;
}) {
  const { kesToMilliSats } = useFx();
  const [node, setNode] = useState<WebLNNode>();
  const [withdrawError, setWithdrawError] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const resp = await webln.getInfo();
        setNode(resp.node);
      } catch (e) {
        console.error(e);
        setWithdrawError(`Withdrawal Failed: ${e}`);
      }
    })();
  }, [webln]);

  const withdrawToWebln = useCallback((): void => {
    if (!amount) {
      return;
    }

    (async () => {
      try {
        const msats = kesToMilliSats(amount).n;
        const { paymentRequest } = await webln.makeInvoice({
          amount: msats / 1000, // amount in sats
          defaultMemo: `Withdraw funds from ${withdrawTarget.target.name}`,
        });

        const { operationId, contractId, fee } = await withdrawClient.lnPay(
          paymentRequest
        );
        updateWithdrawState(TransactionState.Pending);
        console.log(`Contract ID: ${contractId}, Fee: ${fee}`);

        await withdrawClient.awaitLnPay(operationId);

        onTransactionComplete(operationId, msats);
        updateWithdrawState(TransactionState.Complete);
      } catch (e) {
        setWithdrawError(`Withdrawal Failed: ${e}`);
        updateWithdrawState(TransactionState.Failed);
      }
    })();
  }, [
    webln,
    amount,
    withdrawTarget,
    withdrawClient,
    updateWithdrawState,
    onTransactionComplete,
    setWithdrawError,
    kesToMilliSats,
  ]);

  const getFormHelperText = useCallback(
    (amountError?: string) => {
      if (amountError) {
        return <FormHelperText color='red.300'>{amountError}</FormHelperText>;
      }

      if (withdrawError) {
        return <FormHelperText color='red.300'>{withdrawError}</FormHelperText>;
      }

      return (
        <FormHelperText>
          we will send a lightning payment to <strong>{node?.alias}</strong>
        </FormHelperText>
      );
    },
    [node, withdrawError]
  );

  return withdrawState === TransactionState.Create ? (
    <Flex flexDirection='column' gap='5' h='100%' justify='center'>
      <Text>We detected a Lightning wallet</Text>
      <FormControl w='100%'>
        <AmountInputGroup
          amount={amount}
          setAmount={(amount: number) => {
            updateAmount(amount);
            setWithdrawError('');
          }}
          getFormHelperText={getFormHelperText}
        />
        <FormHelperText color='red.300' mt='-2'>
          {withdrawError}
        </FormHelperText>
      </FormControl>
      <Button onClick={withdrawToWebln} variant='solid' colorScheme='green'>
        Withdraw Funds
      </Button>
    </Flex>
  ) : (
    <TransactionStateTracker
      transactionState={withdrawState}
      stateHelperText={{
        value: '',
      }}
      progress={{
        isIndeterminate: true,
        thickness: '4px',
        color: 'green.300',
        size: '3em',
      }}
      icon={{ boxSize: '4em' }}
    />
  );
});

// TODO: use more generic pattern match
const SIGNET_INVOICE_PREFIX = 'lntbs';

const LightningWithdraw = React.memo(function LightningWithdraw({
  withdrawClient,
  withdrawState,
  updateWithdrawState,
  onTransactionComplete,
}: WithdrawExpereinceProps) {
  const [invoice, setInvoice] = useState<string>();
  const [withdrawError, setWithdrawError] = useState<string>();

  const withdrawToInvoice = useCallback(() => {
    (async () => {
      if (!invoice) {
        return;
      }

      try {
        const { operationId, contractId, fee } = await withdrawClient.lnPay(
          invoice
        );
        updateWithdrawState(TransactionState.Pending);
        console.log(`Contract ID: ${contractId}, Fee: ${fee}`);

        await withdrawClient.awaitLnPay(operationId);

        // TODO: Decode invoice amount
        onTransactionComplete(operationId, 0);
        updateWithdrawState(TransactionState.Complete);
      } catch (e) {
        setWithdrawError(`Withdrawal Failed: ${e}`);
        updateWithdrawState(TransactionState.Failed);
      }
    })();
  }, [
    withdrawClient,
    invoice,
    updateWithdrawState,
    onTransactionComplete,
    setWithdrawError,
  ]);

  const getFormHelperText = useCallback(
    (invoiceError?: string) => {
      if (invoiceError) {
        return <FormHelperText color='red.300'>{invoiceError}</FormHelperText>;
      }

      if (withdrawError) {
        return <FormHelperText color='red.300'>{withdrawError}</FormHelperText>;
      }

      return (
        <FormHelperText>
          we will send a lightning payment to the invoice you enter above
        </FormHelperText>
      );
    },
    [withdrawError]
  );

  return withdrawState === TransactionState.Create ? (
    <Flex flexDirection='column' gap='5' h='100%' justify='center'>
      <FormControl>
        <InvoiceInputGroup
          invoice={invoice}
          invoicePrefix={SIGNET_INVOICE_PREFIX}
          setInvoice={setInvoice}
          getFormHelperText={getFormHelperText}
        />
      </FormControl>
      <Button onClick={withdrawToInvoice} variant='solid' colorScheme='green'>
        Withdraw Funds
      </Button>
    </Flex>
  ) : (
    <TransactionStateTracker
      transactionState={withdrawState}
      stateHelperText={{
        value: '',
      }}
      progress={{
        isIndeterminate: true,
        thickness: '4px',
        color: 'green.300',
        size: '3em',
      }}
      icon={{ boxSize: '4em' }}
    />
  );
});
