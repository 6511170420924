export const BS_ENV = process.env.REACT_APP_BS_ENV || 'development';

export const BS_DOMAIN = process.env.REACT_APP_BS_APP_DOMAIN || 'bitsacco.com';

export const BS_BLOG_URL = process.env.REACT_APP_BS_BLOG_URL || '';

export const BS_API_URL = process.env.REACT_APP_BS_API_URL || '';

export const INVITE_CODE = process.env.REACT_APP_BS_INVITE_CODE || '';

export const DISABLE_DEPOSITS =
  process.env.REACT_APP_BS_DISABLE_DEPOSITS === 'true';

export const FEDI_BRAVO_ANDROID_DOWNLOAD_URL =
  'https://play.google.com/store/apps/details?id=com.fedi';

export const FEDI_BRAVO_IOS_DOWNLOAD_URL =
  'https://apps.apple.com/us/app/fedi-alpha/id6448916281';

export const FEDI_BRAVO_APK_DOWNLOAD_URL =
  'https://github.com/fedibtc/fedi-alpha/releases';

export const MUTINY_URL = 'https://mutinynet.com';

export const FEDI_ALPHA_URL = 'https://alpha.fedi.xyz/';

export enum PhoneRegionCode {
  Kenya = 'KE',
  Uganda = 'UG',
}

export const DEBOUNCE_DELAY_MS = 200; // 200ms
export const TOAST_TIMEOUT_MS = 2000; // 2 seconds
export const POLL_INTERVAL_MS = 10000; // 10 seconds
export const POLL_TIMEOUT_MS = 100000; // 100 seconds
