import React, { useCallback } from 'react';
import {
  Chama,
  ChamaMember,
  ChamaMemberRole,
  Currency,
  UpdateChama,
} from '@bitsacco/types';
import {
  useDisclosure,
  Flex,
  Heading,
  Center,
  Divider,
  Button,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { getUserBalanceMsats, getGroupBalanceMsats } from '../../services';
import { TOAST_TIMEOUT_MS } from '../../configs';
import { useApi, useFx } from '../Providers';
import { MemberInfo } from './ChamaInfo';
import { InviteModal } from './InviteModal';
import { ComingSoon } from '../ComingSoon';
import { ChamaViewProps } from './types';

export const ChamaCardMain = React.memo(function ChamaCardMain({
  user,
  chama,
  isAdmin,
}: ChamaViewProps) {
  const { bitsacco } = useApi();
  const { milliSatsToKes } = useFx();
  const toast = useToast();

  const {
    isOpen: showInviteModal,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useDisclosure();

  const renderUserBalance = useCallback(() => {
    const bMsats = getUserBalanceMsats(chama.members, user.id);
    const fx = milliSatsToKes(bMsats);

    return (
      <Flex direction='column' flexGrow={1} w={{ base: '100%', md: 'auto' }}>
        <Heading size='xs'>My Funds</Heading>
        {fx.r === Currency.KES && (
          <Text pt='2'>
            <strong>{fx.n}</strong> KES
          </Text>
        )}
        <Text pt='2'>
          B <strong>{(bMsats / 1000).toFixed(2)}</strong> sats
        </Text>
      </Flex>
    );
  }, [user, chama, milliSatsToKes]);

  const renderGroupBalance = useCallback(() => {
    const bMsats = getGroupBalanceMsats(chama.members);
    const fx = milliSatsToKes(bMsats);

    return (
      <Flex direction='column' flexGrow={1} w={{ base: '100%', md: 'auto' }}>
        <Heading size='xs'>Group Funds</Heading>
        {fx.r === Currency.KES && (
          <Text pt='2'>
            <strong>{fx.n}</strong> KES
          </Text>
        )}
        <Text pt='2'>
          B <strong>{(bMsats / 1000).toFixed(2)}</strong> sats
        </Text>
      </Flex>
    );
  }, [chama, milliSatsToKes]);

  const updateChama = useCallback(
    (payload: UpdateChama, context: string) => {
      try {
        (async () => {
          const updated = await bitsacco.request<Chama, UpdateChama>(
            'PATCH',
            '/chama/update',
            payload
          );

          if (updated) {
            toast({
              title: 'Success',
              description: `Successfully ${context}`,
              status: 'success',
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          } else {
            toast({
              title: 'Error',
              description: `Failed to ${context}`,
              status: 'error',
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          }
        })();
      } catch (e) {
        console.error(e);
        toast({
          title: 'Error',
          description: 'Error when updating chama',
          status: 'error',
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      }
    },
    [bitsacco, toast]
  );

  const configureAdmin = useCallback(
    (member: ChamaMember) => {
      updateChama(
        { id: chama.id, updates: { members: { [member.id]: member } } },
        'reconfigured chama admins'
      );
    },
    [chama, updateChama]
  );

  const removeMember = useCallback(() => {
    console.log('removeMember');
  }, []);

  const memberSort = Object.entries(chama.members).sort(([, a], [, b]) =>
    a.role === ChamaMemberRole.Admin
      ? -1
      : b.role === ChamaMemberRole.Admin
      ? 1
      : 0
  );
  const adminCount = memberSort.filter(
    ([, member]) => member.role === ChamaMemberRole.Admin
  ).length;

  return (
    <>
      <Flex direction='column' gap={4}>
        <Flex
          flexDirection='column'
          px={{ base: '', md: '3' }}
          gap={{ base: '6', lg: '6' }}
        >
          <Heading size='sm' textTransform='uppercase'>
            Funds
          </Heading>
          <Flex
            display='flex'
            flexDirection='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            justifyContent='space-between'
            px={{ base: '', md: '3' }}
            gap={{ base: '6', lg: '6' }}
          >
            {renderGroupBalance()}
            <Center height={{ base: '0%', sm: '80px' }}>
              <Divider orientation='vertical' />
            </Center>
            {renderUserBalance()}
          </Flex>
        </Flex>

        <Divider orientation='horizontal' />

        <Flex direction='column' gap={4} flexGrow={1}>
          <Flex
            gap={'4'}
            direction='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            align='center'
            alignItems={'center'}
            justifyItems={'center'}
            justify='space-between'
          >
            <Heading size='sm' textTransform='uppercase'>
              Members
            </Heading>
            {isAdmin && (
              <Button
                variant='outline'
                colorScheme='green'
                height={'35px'}
                onClick={onOpenInviteModal}
              >
                + invite
              </Button>
            )}
          </Flex>

          <Flex pt={2} flexDirection='row' flexWrap={{ base: 'wrap' }} gap={2}>
            {memberSort.map(([idx, member]) => {
              return (
                <MemberInfo
                  key={idx}
                  member={member}
                  user={user}
                  showMenu={isAdmin}
                  adminCount={adminCount}
                  configureAdmin={configureAdmin}
                  removeMember={removeMember}
                />
              );
            })}
          </Flex>
          <Divider orientation='horizontal' />
          <Heading size='sm' textTransform='uppercase'>
            Rules
          </Heading>
          <UnorderedList gap={2}>
            <ComingSoon />
          </UnorderedList>
        </Flex>
      </Flex>
      <InviteModal
        chama={chama}
        isOpen={showInviteModal}
        onClose={onCloseInviteModal}
      />
    </>
  );
});
