import React, { useCallback } from 'react';
import {
  VStack,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import { User, RecoverUser } from '@bitsacco/types';
import {
  isValidPhone,
  isValidPin,
  digitizePhone,
  isValidNpub,
} from '../../utils';
import { PinStack } from './PinStack';
import { useApi, useAuth } from '../Providers';
import { AuthStackProps, AuthExperience } from './types';

export const RecoverStack = ({
  pin,
  phone,
  useNpub,
  npub,
  authError,
  setPin,
  setPhone,
  setNpub,
  setAuthError,
  setAuthExperience,
  setUseNpub,
}: AuthStackProps) => {
  const { bitsacco } = useApi();
  const { login } = useAuth();

  const recoverUser = useCallback(() => {
    if (!useNpub && !isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (useNpub && !isValidNpub(npub)) {
      setAuthError('invalid nostr identity');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, RecoverUser>(
          'POST',
          '/user/recover',
          {
            phone: useNpub ? null : digitizePhone(phone),
            npub,
            pin,
          }
        );

        if (user) {
          return login(user);
        }

        throw 'Failed to recover account';
      } catch (e) {
        setAuthError(`${e}`);
      }
    })();
  }, [bitsacco, phone, npub, useNpub, pin, login, setAuthError]);

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            RECOVER ACCOUNT
          </Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4}>
            <PinStack
              {...{
                pin,
                phone,
                useNpub,
                npub,
                authError,
                setPin,
                setPhone,
                setNpub,
                setAuthError,
                recoverUser,
              }}
            />
            <Button variant='link' onClick={() => setUseNpub(!useNpub)}>
              use {useNpub ? 'phone' : 'nostr'}
            </Button>
          </VStack>
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Login)}
        >
          LOGIN
        </Button>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Signup)}
        >
          SIGNUP
        </Button>
      </Flex>
    </VStack>
  );
};
