import React, { useState } from 'react';
import {
  useToast,
  FormControl,
  Flex,
  InputGroup,
  Input,
  ButtonGroup,
  Button,
  Text,
  FormHelperText,
  Spinner,
} from '@chakra-ui/react';
import { sendNostrDM } from '../services';
import { TOAST_TIMEOUT_MS } from '../configs';
import { TemplateModal } from './TemplateModal';
import { useAuth } from './Providers';

export const Feedback = React.memo(function BitsaccoPilotFeedback({
  isOpenFeedback,
  onCloseFeedback,
}: {
  isOpenFeedback: boolean;
  onCloseFeedback: () => void;
}): JSX.Element {
  const { user } = useAuth();
  const toast = useToast();

  const [feedback, setFeedback] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const [sendError, setSendError] = useState<string>('');

  const sendFeedback = async () => {
    try {
      if (feedback.trim() === '') {
        return;
      }

      const notification = {
        message: feedback,
        sender: {
          id: user?.id,
          phone: user?.phone,
          profile: user?.profile,
        },
      };

      setSending(true);
      await sendNostrDM(JSON.stringify(notification));
      setFeedback('');

      toast({
        title: 'Success',
        description: 'Sent feedback',
        status: 'success',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });

      onCloseFeedback();
      setSendError('');
    } catch (e) {
      console.error(`${e}`);
      setSendError('Failed to send feedback. Please reload page and try again');
      toast({
        title: 'Error',
        description: 'Failed to send feedback',
        status: 'error',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    } finally {
      setSending(false);
    }
  };

  return (
    <TemplateModal
      isOpen={isOpenFeedback}
      isCentered={false}
      onClose={onCloseFeedback}
      header={<Text>Bitsacco Pilot Feedback</Text>}
      body={
        <FormControl>
          <Flex mt={2} gap={2} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
            <InputGroup>
              <Input
                value={feedback}
                onChange={(e) => {
                  setFeedback(e.target.value);
                  setSendError('');
                }}
                placeholder='Type your feedback message...'
                border='2px solid teal'
              />
            </InputGroup>
          </Flex>
          {sendError ? (
            <FormHelperText mt={4} fontSize='sm' color='red.500'>
              {sendError}
            </FormHelperText>
          ) : (
            <FormHelperText mt={4} fontSize='sm' color='gray.500'>
              Bitsacco gets better with your feedback! Thank you for taking the
              time to send us your thoughts and suggestions.
            </FormHelperText>
          )}
        </FormControl>
      }
      footer={
        <ButtonGroup
          w='100%'
          display='flex'
          flexDirection='row'
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          justifyContent='space-between'
          spacing={{ base: '0', lg: '4' }}
          gap={'2'}
        >
          <Button
            onClick={sendFeedback}
            variant='outline'
            colorScheme='teal'
            isDisabled={!feedback || sending}
          >
            <Flex gap={2} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
              Send Feedback
              {sending && <Spinner size='sm' color='teal' />}
            </Flex>
          </Button>
          <Button onClick={onCloseFeedback} variant='ghost' colorScheme='red'>
            Cancel
          </Button>
        </ButtonGroup>
      }
    />
  );
});
