export interface SavingPlan {
  /**
   * Unique identifier for the saving plan.
   */
  id: string;
  /**
   * Phone number of user who made the saving plan.
   */
  user: string;
  /**
   * Amount to be deposited at each interval.
   */
  deposit: number;
  /**
   * The interval at which the deposit is to be made.
   */
  interval: SavingPlanInterval;
  /**
   * The status of the saving plan.
   */
  active: boolean;
  /**
   * The maximum number of deposits to be made.
   */
  limit?: number;
}

export enum SavingPlanInterval {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export type CreateSavingPlan = Omit<SavingPlan, 'id'>;
export type FindSavingPlans = Pick<SavingPlan, 'user'>;
export type UpdateSavingPlan = {
  id: string;
  updates: Partial<Omit<SavingPlan, 'id' | 'user'>>;
};
export type DeleteSavingPlan = Pick<SavingPlan, 'id'>;
