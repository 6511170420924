import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  Chama,
  ChamaMemberRole,
  ChamaTx,
  ChamaTxRequest,
  ChamaTxReview,
  ChamaTxState,
  FindChamaTx,
  ReviewChamaTx,
  User,
} from '@bitsacco/types';
import { RouterLink, useApi, useAuth } from '../components';
import { TOAST_TIMEOUT_MS } from '../configs';
import { deepEqual } from '../utils';

export const Transaction = React.memo(function Transaction(): JSX.Element {
  const { bitsacco } = useApi();
  const { nextRedirect, user } = useAuth();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { decodedToken } = useJwt<ChamaTxRequest>(searchParams.get('t') || '');

  const [chama, setChama] =
    useState<Pick<Chama, 'id' | 'name' | 'description'>>();
  const [member, setMember] =
    useState<Pick<User, 'id' | 'phone' | 'profile'>>();
  const [tx, setTx] = useState<ChamaTx>();
  const [txError, setTxError] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [showAuth, setShowAuth] = useState(false);

  const [txFinalised, setTxFinalised] = useState(false);
  const [userHasApproved, setUserHasApproved] = useState(false);
  const [userhasRejected, setUserHasRejected] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && showAuth) {
      setShowAuth(false);
    }
  }, [user, showAuth]);

  useEffect(() => {
    if (!user) {
      nextRedirect(`${location.pathname}${location.search}`);
      setShowAuth(true);
      setLoading(false);
      return;
    }

    if (decodedToken) {
      const verifyTxData = async (txid: string, cid: string, uid: string) => {
        const chamaTx = await bitsacco.request<ChamaTx, FindChamaTx>(
          'POST',
          '/chama/tx/find',
          {
            id: txid,
          }
        );

        if (!chamaTx) {
          throw 'Transaction not found';
        }

        if (cid !== chamaTx.meta.chama || uid !== chamaTx.meta.user) {
          console.error('Mismatched tx chama');
          throw 'Mismatched transaction data';
        }

        setTxFinalised(
          chamaTx.state !== ChamaTxState.Pending &&
            chamaTx.state !== ChamaTxState.Approved &&
            chamaTx.state !== ChamaTxState.Rejected
        );

        const userApproval = chamaTx.approvals.find((approval) =>
          deepEqual(approval, {
            id: user.id,
            phone: user.phone,
            role: ChamaMemberRole.Admin,
          })
        );

        if (userApproval) {
          setUserHasApproved(true);
          setUserHasRejected(false);
          return;
        }

        const userRejection = chamaTx.rejections.find((rejection) =>
          deepEqual(rejection, {
            id: user.id,
            phone: user.phone,
            role: ChamaMemberRole.Admin,
          })
        );

        if (userRejection) {
          setUserHasRejected(true);
          setUserHasApproved(false);
          return;
        }
      };

      const { chama, user: member, tx } = decodedToken;
      verifyTxData(tx.id, chama.id, member.id)
        .then(() => {
          setChama(chama);
          setMember(member);
          setTx(tx);
          setTxError('');
        })
        .catch((e) => {
          console.error(e);
          setTxError(`${e}`);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if ((!chama || !member || !tx) && !loading) {
      setTxError('Invalid transaction data');
    }
  }, [bitsacco, user, decodedToken, chama, member, tx, loading, location, nextRedirect, setTxFinalised]);

  const reviewTransaction = useCallback(
    (review: ChamaTxReview) => {
      if (!user || !tx) {
        return;
      }

      (async () => {
        try {
          const updated = await bitsacco.request<ChamaTx, ReviewChamaTx>(
            'POST',
            '/chama/tx/review',
            {
              id: tx.id,
              reviewer: {
                id: user.id,
                role: ChamaMemberRole.Admin,
              },
              review,
            }
          );

          if (updated) {
            setTxError('');
            return navigate('/chama');
          }

          throw 'failed to review transaction';
        } catch (e) {
          setTxError(`${e}`);
          toast({
            title: 'Warning',
            description: 'Failed to review transaction',
            status: 'warning',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        }
      })();
    },
    [bitsacco, user, tx, toast, navigate, setTxError]
  );

  const getHelperText = useCallback(() => {
    if (txError) {
      <Text color='red.500' textAlign='center'>
        {txError}
      </Text>;
    }

    if (txFinalised) {
      return (
        <Text color='teal.500' textAlign='center'>
          This transaction has already been finalised
        </Text>
      );
    }

    if (userHasApproved) {
      return (
        <Text color='teal.500' textAlign='center'>
          You have already approved this transaction
        </Text>
      );
    }

    if (userhasRejected) {
      return (
        <Text color='teal.500' textAlign='center'>
          You have already rejected this transaction
        </Text>
      );
    }

    return <></>;
  }, [txError, txFinalised, userHasApproved, userhasRejected]);

  if (loading) {
    return (
      <Center>
        <Spinner size='xl' color='teal' />
      </Center>
    );
  }

  if (showAuth) {
    return (
      <Flex direction='column' flexGrow={1} basis='100%' gap='5' p='5'>
        <Center pt='10'>
          <VStack>
            <Card align='center' w={'400px'} px={'5'}>
              <CardHeader
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <Text fontSize='xl' fontWeight='semibold'>
                  Review Withdrawal Request
                </Text>
                <Text>{chama?.name}</Text>
              </CardHeader>
              <CardBody>
                <Flex w='100%' align='center' flexDir={'column'} gap={4}>
                  <Text textAlign='center'>
                    someone requested a withdrawal from your chama
                  </Text>
                  <Button
                    variant='solid'
                    colorScheme='teal'
                    w='100%'
                    onClick={() =>
                      navigate('/auth', {
                        state: {
                          from: `${location.pathname}${location.search}`,
                        },
                      })
                    }
                  >
                    Login to Review
                  </Button>
                </Flex>
              </CardBody>
            </Card>
            <RouterLink to='/chama'>
              <Button variant='link'>exit review</Button>
            </RouterLink>
          </VStack>
        </Center>
      </Flex>
    );
  }

  if (!chama || !member || !tx) {
    return (
      <Flex direction='column' flexGrow={1} basis='100%' p='5'>
        <Center>
          <Text color='red.500'>Invalid transaction data</Text>
        </Center>
      </Flex>
    );
  }

  return (
    <Flex direction='column' flexGrow={1} basis='100%' gap='5' p='5'>
      <Center pt='10'>
        <VStack>
          <Card align='center' w={'400px'} px={'5'}>
            <CardHeader
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <Text fontSize='xl' fontWeight='semibold'>
                Review Withdrawal Request
              </Text>
              <Text>{chama?.name}</Text>
            </CardHeader>
            <CardBody>
              <Flex w='100%' align='center' flexDir={'column'} gap={4}>
                <Text textAlign='center'>
                  {member?.phone} requested a withdrawal of {tx?.amount} sats
                  from <em>{chama?.name}</em>
                </Text>
                {getHelperText()}
                <ButtonGroup
                  w='100%'
                  display='flex'
                  flexDirection='row'
                  flexWrap={{ base: 'wrap', lg: 'nowrap' }}
                  justifyContent='space-between'
                  spacing={{ base: '0', lg: '4' }}
                  gap={'2'}
                >
                  <Button
                    variant='solid'
                    colorScheme='teal'
                    w='100%'
                    onClick={() => reviewTransaction(ChamaTxReview.Approve)}
                    isDisabled={txFinalised || userHasApproved}
                  >
                    Approve
                  </Button>
                  <Button
                    variant='solid'
                    colorScheme='teal'
                    w='100%'
                    onClick={() => reviewTransaction(ChamaTxReview.Reject)}
                    isDisabled={txFinalised || userhasRejected}
                  >
                    Reject
                  </Button>
                </ButtonGroup>
              </Flex>
            </CardBody>
          </Card>
          <RouterLink to='/chama'>
            <Button variant='link'>exit review</Button>
          </RouterLink>
        </VStack>
      </Center>
    </Flex>
  );
});
