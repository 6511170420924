import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CircularProgress,
  CircularProgressProps,
  Flex,
  Icon,
  IconProps,
} from '@chakra-ui/react';
import { TimeIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';

interface TemplateModalProps {
  header?: JSX.Element;
  body: JSX.Element;
  footer?: JSX.Element;
  isOpen: boolean;
  isCentered?: boolean;
  onClose: () => void;
}

export const TemplateModal = React.memo(function TemplateModal({
  header,
  body,
  footer,
  isOpen,
  isCentered = true,
  onClose,
}: TemplateModalProps): JSX.Element {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered={isCentered}
      closeOnOverlayClick={false}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export enum ActionState {
  Create,
  Pending,
  Processing,
  Complete,
  Failed,
}

export interface ProgressViewerProps {
  state: ActionState;
  circularProgress: CircularProgressProps;
  icon: IconProps;
}

export const ProgressViewer = React.memo(function ProgressViewer({
  state,
  circularProgress,
  icon,
}: ProgressViewerProps) {
  return (
    <Flex justify='center' align='center' h='14em'>
      {state === ActionState.Pending && (
        <CircularProgress {...circularProgress} />
      )}
      {state === ActionState.Processing && (
        <Icon as={TimeIcon} {...icon} color='green.500' />
      )}
      {state === ActionState.Complete && (
        <Icon as={CheckIcon} {...icon} color='green.500' />
      )}
      {state === ActionState.Failed && (
        <Icon as={CloseIcon} {...icon} color='red.300' />
      )}
    </Flex>
  );
});
