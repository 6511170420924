import { SESSION_COOKIE_KEY, SESSION_HEADER_KEY } from '@bitsacco/types';
import {
  getSessionValue,
  setSessionValue,
  SESSION_USER_KEY,
  removeSessionValue,
} from '../services/storage';

// Custom API fetcher using fetch
const fetcher = async <T, B>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' = 'GET',
  body?: B
): Promise<T> => {
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getSessionValue(SESSION_COOKIE_KEY)}`,
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const res = await fetch(url, options);

  // Handle session creation paths
  const SessionCreatePaths = ['/user/login', '/user/register', '/user/recover'];
  if (SessionCreatePaths.includes(url)) {
    const sessionId = res.headers.get(SESSION_HEADER_KEY);
    sessionId && setSessionValue(SESSION_COOKIE_KEY, sessionId);
  }

  if (!res.ok) {
    if (res.status === 401) {
      removeSessionValue(SESSION_COOKIE_KEY);
      removeSessionValue(SESSION_USER_KEY);
      throw new Error('Unauthorized');
    }
    throw new Error(await res.text());
  }

  return res.json();
};

export default fetcher;
