import React, { useCallback, useEffect, useState } from 'react';
import {
  Text,
  Flex,
  Heading,
  Card,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  useDisclosure,
  useTheme,
  IconButton,
  Spacer,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import { FaList, FaEllipsisVertical } from 'react-icons/fa6';
import { InfoResponse, LightningPayResponse } from 'fedimint-ts/dist/types.js';
import {
  DeleteSavingPlan,
  SavingPlan,
  UpdateSavingPlan,
  User,
} from '@bitsacco/types';
import {
  useApi,
  useAuth,
  DepositModal,
  DepositTarget,
  SavingPlanModal,
  WithdrawModal,
  WithdrawClient,
  WithdrawTarget,
  useFx,
  ComingSoon,
} from '../components';
import { formatNumber } from '../utils';
import { TOAST_TIMEOUT_MS } from '../configs';

export const Savings = React.memo(function AccessSavings(): JSX.Element {
  const { user } = useAuth();
  const { bitsacco } = useApi();

  const [savingsPlans, setSavingsPlans] = useState<SavingPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<SavingPlan | undefined>();

  const [fetchPlans, setFetchPlans] = useState<boolean>(true);

  const toast = useToast();

  useEffect(() => {
    if (!user || !fetchPlans) {
      return;
    }

    (async () => {
      try {
        const plans = await bitsacco.request<SavingPlan[], { user: string }>(
          'POST',
          '/saving/find',
          {
            user: user.id,
          }
        );
        plans && setSavingsPlans(plans);
        setFetchPlans(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [bitsacco, user, fetchPlans, setFetchPlans, setSavingsPlans]);

  const {
    isOpen: showSavingPlanModal,
    onOpen: onOpenSavingPlanModal,
    onClose: onCloseSavingPlanModal,
  } = useDisclosure();

  const editPlan = useCallback(
    (plan: SavingPlan) => {
      setSelectedPlan(plan);
      onOpenSavingPlanModal();
    },
    [setSelectedPlan, onOpenSavingPlanModal]
  );

  const pausePlan = useCallback(
    (plan: SavingPlan) => {
      (async () => {
        (async () => {
          const { id, active } = plan;

          const toggleAction = active ? 'paused' : 'activated';

          try {
            const plan = await bitsacco.request<SavingPlan, UpdateSavingPlan>(
              'PATCH',
              '/saving/update',
              {
                id,
                updates: {
                  active: !active,
                },
              }
            );

            if (plan) {
              console.log(`${toggleAction} saving plan`);
              setFetchPlans(true);
              toast({
                title: 'Success',
                description: `Saving plan has been ${toggleAction}`,
                status: 'success',
                duration: TOAST_TIMEOUT_MS,
                isClosable: true,
              });
              return;
            }

            throw `Planning error`;
          } catch (e) {
            console.error(e);
          }
        })();
      })();
    },
    [bitsacco, toast, setFetchPlans]
  );

  const deletePlan = useCallback(
    (plan: SavingPlan) => {
      (async () => {
        try {
          const res = await bitsacco.request<object, DeleteSavingPlan>(
            'DELETE',
            '/saving/delete',
            {
              id: plan.id,
            }
          );

          if (res) {
            console.log('deleted saving plan');
            setFetchPlans(true);
            toast({
              title: 'Success',
              description: 'Saving plan has been deleted',
              status: 'success',
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
            return;
          }

          throw `error`;
        } catch (e) {
          console.error(e);
          toast({
            title: 'Warning',
            description: 'Failed to delete saving plan',
            status: 'warning',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        }
      })();
    },
    [bitsacco, toast, setFetchPlans]
  );

  const closeModal = useCallback(() => {
    setSelectedPlan(undefined);
    onCloseSavingPlanModal();
    setFetchPlans(true);
  }, [onCloseSavingPlanModal, setFetchPlans, setSelectedPlan]);

  return (
    <Flex
      direction='column'
      flexGrow={1}
      basis='100%'
      gap='5'
      px='5'
      pb='150px'
    >
      <Flex p={'5'} direction='row' align='center' justify='start'>
        <Heading size='md'>PERSONAL SAVINGS</Heading>
        <Text p='3'>|</Text>
        <Text>accumulate wealth by saving in bitcoin</Text>
      </Flex>
      {user && <BalanceCard user={user} />}
      <Flex
        mt='10'
        p={'5'}
        gap={'4'}
        direction='row'
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        align='center'
        alignItems={'center'}
        justifyItems={'center'}
        justify='space-between'
      >
        <Flex
          gap={'4'}
          direction='row'
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          align='center'
          alignItems={'center'}
          justifyItems={'center'}
        >
          <Heading size='md'>AUTOMATED SAVINGS</Heading>
          <ComingSoon />
        </Flex>
        <Button
          variant='outline'
          colorScheme='green'
          height={'35px'}
          onClick={onOpenSavingPlanModal}
        >
          + create
        </Button>
      </Flex>
      <Flex direction='column' justify='start'>
        <Text px={{ base: '0', md: '5' }}>
          We send you periodic reminders to deposit funds from Mpesa, into your
          savings account
        </Text>
        <Flex direction='column' justify='center' px={{ base: '0', md: '5' }}>
          {savingsPlans.map((plan) => (
            <SavingPlanView
              key={plan.id}
              plan={plan}
              editPlan={editPlan}
              pausePlan={pausePlan}
              deletePlan={deletePlan}
            />
          ))}
        </Flex>
      </Flex>
      {user && (
        <SavingPlanModal
          user={user}
          plan={selectedPlan}
          isOpen={showSavingPlanModal}
          onClose={closeModal}
        />
      )}
    </Flex>
  );
});

interface BalanceCardProps {
  user: User;
}

const BalanceCard = React.memo(function BalanceCard({
  user,
}: BalanceCardProps): JSX.Element {
  const { milliSatsToKes } = useFx();
  const [balanceMsats] = useState<number>(0);

  const {
    isOpen: showDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useDisclosure();

  const {
    isOpen: showWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  // const depositClient = {
  //   createInvoice: (
  //     msats: number,
  //     memo: string
  //   ): Promise<LightningInvoiceResponse> => {
  //     console.log(msats, memo);
  //     return Promise.resolve({
  //       operationId: '',
  //       invoice: '',
  //     });
  //   },
  //   awaitInvoice: (operationId: string): Promise<InfoResponse> => {
  //     console.log(operationId);
  //     return Promise.resolve({
  //       foo: {
  //         network: '',
  //         meta: {},
  //         totalAmountMsat: 0,
  //         totalNumNotes: 0,
  //         denominationsMsat: {
  //           tiered: [],
  //         },
  //       },
  //     });
  //   },
  // };

  const withdrawClient: WithdrawClient = {
    lnPay: (invoice: string): Promise<LightningPayResponse> => {
      console.log(invoice);
      return Promise.resolve({
        operationId: '',
        paymentType: '',
        contractId: '',
        fee: 0,
      });
    },
    awaitLnPay: (operationId: string): Promise<InfoResponse> => {
      console.log(operationId);
      return Promise.resolve({
        foo: {
          network: '',
          meta: {},
          totalAmountMsat: 0,
          totalNumNotes: 0,
          denominationsMsat: {
            tiered: [],
          },
        },
      });
    },
  };

  const transactionTarget: DepositTarget | WithdrawTarget = {
    target: {
      id: '',
      name: 'Bitsacco Savings',
    },
    user,
  };

  const onTransactionComplete = useCallback((txid: string, amount: number) => {
    console.log(`Transaction complete: ${amount} msats`);
  }, []);

  const getHeading = useCallback(() => {
    const { n, r } = milliSatsToKes(balanceMsats);

    return (
      <Heading fontSize={{ base: '30px', md: '48px' }} fontWeight='bold'>
        {formatNumber(n, r)} KES
      </Heading>
    );
  }, [balanceMsats, milliSatsToKes]);

  return (
    <Flex
      display={'flex'}
      alignItems={'center'}
      justifyItems={'center'}
      justifyContent={'center'}
      w={'100%'}
    >
      <Card w={{ base: '600px', xl: '800px' }} h={'350px'}>
        <CardHeader
          display='flex'
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <ComingSoon />
          <Spacer />
          <Button
            as={IconButton}
            aria-label='Options'
            colorScheme='teal'
            icon={<FaList />}
            variant='ghost'
          />
        </CardHeader>
        <CardBody
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyItems={'center'}
          justifyContent={'center'}
        >
          {getHeading()}
          <Text fontSize={{ base: '24px', lg: '30px' }} fontWeight='bold'>
            B {formatNumber(balanceMsats / 1000)} sats
          </Text>
        </CardBody>
        <CardFooter
          display={'flex'}
          alignItems={'center'}
          justifyItems={'center'}
          justifyContent={'center'}
        >
          <ButtonGroup
            gap={'4'}
            spacing={{ base: '0', lg: '4' }}
            display={'flex'}
            flexDir={'row'}
            flexBasis={'100%'}
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          >
            <Button
              variant='solid'
              colorScheme='green'
              onClick={onOpenDepositModal}
              height={'45px'}
              minW={'150px'}
              flexGrow={1}
              isDisabled={true}
            >
              Deposit Funds
            </Button>
            <Button
              variant='solid'
              colorScheme='teal'
              onClick={onOpenWithdrawModal}
              height={'45px'}
              minW={'150px'}
              flexGrow={1}
              isDisabled={true}
            >
              Withdraw Funds
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
      <DepositModal
        depositTarget={transactionTarget}
        isOpen={showDepositModal}
        onClose={onCloseDepositModal}
      />
      <WithdrawModal
        withdrawClient={withdrawClient}
        withdrawTarget={transactionTarget}
        isOpen={showWithdrawModal}
        onClose={onCloseWithdrawModal}
        onTransactionComplete={onTransactionComplete}
      />
    </Flex>
  );
});

interface SavingPlanViewProps {
  plan: SavingPlan;
  editPlan: (plan: SavingPlan) => void;
  pausePlan: (plan: SavingPlan) => void;
  deletePlan: (plan: SavingPlan) => void;
}

const SavingPlanView = ({
  plan,
  editPlan,
  pausePlan,
  deletePlan,
}: SavingPlanViewProps) => {
  const theme = useTheme();

  const activeColor = useCallback(
    (level: number) => {
      return plan.active ? theme.colors.green[level] : theme.colors.gray[level];
    },
    [theme.colors, plan.active]
  );

  return (
    <Flex
      mt='10'
      p={'5'}
      gap={'4'}
      direction='row'
      align={'center'}
      justify='space-between'
      borderWidth={'2px'}
      borderColor={activeColor(100)}
      borderRadius={'10px'}
    >
      <Flex
        gap={'4'}
        direction='row'
        flexGrow={1}
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        align={'center'}
        justify='space-between'
      >
        <Text>
          Save <strong>{plan.deposit}</strong> Kenya Shillings{' '}
          <strong>{plan.interval}</strong>
        </Text>
        <Spacer />
        <ComingSoon />
        <Text color={activeColor(500)}>
          {plan.active ? 'active' : 'paused'}
        </Text>
      </Flex>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<FaEllipsisVertical />}
          variant='none'
          size={'md'}
        />
        <MenuList p={'0'}>
          <MenuItem
            w={'100%'}
            textAlign={'center'}
            onClick={() => editPlan(plan)}
          >
            edit
          </MenuItem>
          <MenuItem
            w={'100%'}
            textAlign={'center'}
            onClick={() => pausePlan(plan)}
          >
            {plan.active ? 'pause' : 'activate'}
          </MenuItem>
          <MenuItem
            w={'100%'}
            textAlign={'center'}
            onClick={() => deletePlan(plan)}
          >
            delete
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};
