import React, { ErrorInfo, ReactNode } from 'react';
import { Flex, Icon, Text, theme, useMediaQuery } from '@chakra-ui/react';
import { useRouteError } from 'react-router-dom';
import { ReactComponent as LogoFull } from '../assets/svgs/logo_full.svg';
import { ReactComponent as Logo } from '../assets/svgs/logo.svg';
import { RouterLink } from '../components';

export const ErrorPage = React.memo(function ErrorPage(): JSX.Element {
  const [isSmallScreen] = useMediaQuery('(max-width: 767px)');

  const error = useRouteError();
  console.error(error);

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      h='100vh'
      w='100%'
      bg='gray.100'
      gap='4'
    >
      <RouterLink to='/'>
        <Icon
          as={isSmallScreen ? Logo : LogoFull}
          fill={theme.colors.teal[500]}
          height='200px'
          width={'100%'}
        />
      </RouterLink>
      <Text
        textAlign={'center'}
        fontSize={'24px'}
        letterSpacing={'0.5px'}
        fontFamily={'body'}
        color={theme.colors.gray[500]}
      >
        sorry, something went wrong!!
      </Text>
      <Text
        textAlign={'center'}
        fontSize={'24px'}
        letterSpacing={'0.5px'}
        fontFamily={'body'}
        color={theme.colors.teal[500]}
      >
        click on logo above
      </Text>
    </Flex>
  );
});
interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e: Error): ErrorBoundaryState {
    console.log(e);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}
