import React from 'react';
import {
  Button,
  Center,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { ReactComponent as SavingsIcon } from '../assets/svgs/savings.svg';
import { ReactComponent as CommunityIcon } from '../assets/svgs/community.svg';
import { ReactComponent as CreditIcon } from '../assets/svgs/credit.svg';
import { SectionTemplate } from './SectionTemplate';
import { RouterLink } from './RouterLink';

export const FeaturesSection = React.memo(
  function FeaturesSection(): JSX.Element {
    return (
      <SectionTemplate
        heading='ACCESS THESE FEATURES'
        subheading='Enjoy these everyday financial features using Bitcoin, from within
              Bitsacco'
      >
        <Flex
          gap={'24px'}
          display={{ base: 'flex', md: 'grid' }}
          flexDir={{ base: 'column' }}
          gridTemplateColumns={{
            md: 'repeat(auto-fit, minmax(290px, 1fr))',
          }}
          alignItems={'center'}
          justifyItems={'center'}
          w={'100%'}
        >
          <FeatureBlock
            title={'Chama Savings'}
            body={`Create or join an investment groups with friends, family and colleagues. We help you manage your group investments, track contributions and investments, and provide solutions for efficient and fair Chamas.`}
            imgUrl={CommunityIcon}
            link={{
              text: 'Preview',
              to: '/chama',
            }}
          />
          <FeatureBlock
            title={'Personal Savings'}
            body={`Start accumulating wealth by saving in Bitcoin. We help you secure your savings, and support you through time tested investment strategies like cost averaging and automation of investments.`}
            imgUrl={SavingsIcon}
            link={{
              text: 'Preview',
              to: '/personal',
            }}
          />
          <FeatureBlock
            title={'Loans'}
            body={`Access loan facilities using your Bitcoin savings as collateral. Borrow as an individual, or as a Chama. We provide you with access to credit facilities at competitive rates, and with flexible repayment terms.`}
            imgUrl={CreditIcon}
            link={{
              text: 'Coming Soon',
            }}
          />
        </Flex>
      </SectionTemplate>
    );
  }
);

interface FeatureBlockProps {
  title: string;
  body: string;
  imgUrl: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  link?: {
    text: string;
    to?: string;
  };
}

export const FeatureBlock = React.memo(function FeatureBlock({
  title,
  body,
  imgUrl,
  link,
}: FeatureBlockProps): JSX.Element {
  const theme = useTheme();
  const bg = useColorModeValue(theme.colors.gray[50], theme.colors.gray[500]);

  return (
    <Flex
      border={'3px solid teal'}
      h={'500px'}
      w={{ base: '300px', xl: '350px' }}
      rounded={'12px'}
      flexDir={'column'}
    >
      <Flex
        bgColor={bg}
        height={'130px'}
        w={'100%'}
        rounded={'8px'}
        alignItems={'flex-end'}
        justifyContent={'center'}
      >
        <Center
          bgColor={'white'}
          h={'120px'}
          w={'120px'}
          rounded={'50%'}
          mb={'-60px'}
        >
          <Center
            h={'100px'}
            w={'100px'}
            p='1'
            rounded={'50%'}
            border={'1px solid teal'}
            overflow={'hidden'}
          >
            <Icon as={imgUrl} height={'100%'} width={'100%'} />
          </Center>
        </Center>
      </Flex>
      <Flex flexDir={'column'} gap={'4'} pt={'80.5px'} px={'2'}>
        <Text
          textAlign={'center'}
          fontSize={'24px'}
          letterSpacing={'0.5px'}
          fontFamily={'body'}
          fontWeight={'700'}
        >
          {title}
        </Text>
        <Text textAlign={'center'} fontFamily={'body'}>
          {body}
        </Text>
      </Flex>
      {link && (
        <RouterLink to={link?.to || ''}>
          <Button mt='5' isDisabled={link.to === undefined} w='100%'>
            {link.text}
          </Button>
        </RouterLink>
      )}
    </Flex>
  );
});
