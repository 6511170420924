import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormHelperText,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  CreateSavingPlan,
  SavingPlan,
  SavingPlanInterval,
  UpdateSavingPlan,
  User,
} from '@bitsacco/types';
import { AmountInputGroup, OptionInputGroup } from './InputGroups';
import { TemplateModal } from './TemplateModal';
import { useApi } from './Providers';
import { TOAST_TIMEOUT_MS } from '../configs';

interface SavingPlanModalProps {
  user: User;
  plan?: SavingPlan;
  isOpen: boolean;
  onClose: () => void;
}

export const SavingPlanModal = React.memo(function SavingPlanModal({
  user: { phone, id },
  plan,
  isOpen,
  onClose,
}: SavingPlanModalProps): JSX.Element {
  const { bitsacco } = useApi();

  const [deposit, setDeposit] = useState<number>(0);
  const [interval, setInterval] = useState<SavingPlanInterval>(
    SavingPlanInterval.Daily
  );

  const toast = useToast();

  useEffect(() => {
    if (plan) {
      setDeposit(plan.deposit);
      setInterval(plan.interval);
    }
  }, [plan]);

  const closeModal = useCallback(() => {
    setDeposit(0);
    setInterval(SavingPlanInterval.Daily);
    onClose();
  }, [onClose]);

  const createSavingPlan = useCallback(() => {
    (async () => {
      try {
        const plan = await bitsacco.request<SavingPlan, CreateSavingPlan>(
          'POST',
          '/saving/create',
          {
            user: id,
            deposit,
            interval,
            active: true,
          }
        );

        if (plan) {
          console.log('created saving plan');
          toast({
            title: 'Success',
            description: 'Saving plan has been created',
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
          closeModal();
          return;
        }

        throw `Planning error`;
      } catch (e) {
        console.warn(e);
        toast({
          title: 'Warning',
          description: 'Failed to create saving plan',
          status: 'warning',
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      }
    })();
  }, [bitsacco, id, deposit, interval, toast, closeModal]);

  const updateSavingPlan = useCallback(() => {
    if (!plan) {
      return;
    }

    (async () => {
      const id = plan.id;

      try {
        const plan = await bitsacco.request<SavingPlan, UpdateSavingPlan>(
          'PATCH',
          '/saving/update',
          {
            id,
            updates: {
              deposit,
              interval,
            },
          }
        );

        if (plan) {
          console.log('updated saving plan');
          toast({
            title: 'Success',
            description: 'Saving plan has been updated',
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
          closeModal();
          return;
        }

        throw `Planning error`;
      } catch (e) {
        console.warn(e);
        toast({
          title: 'Warning',
          description: 'Failed to update saving plan',
          status: 'warning',
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      }
    })();
  }, [bitsacco, plan, deposit, interval, toast, closeModal]);

  const getInfoText = useCallback(() => {
    return (
      <Text>
        We will send a <strong>{interval}</strong> reminder to{' '}
        <strong>{phone}</strong>
      </Text>
    );
  }, [interval, phone]);

  const getModalBody = useCallback(() => {
    return (
      <Flex flexDirection='column' gap='5'>
        <FormControl>
          <FormHelperText pb='2'>Deposit Amount</FormHelperText>
          <AmountInputGroup
            amount={deposit}
            setAmount={setDeposit}
            getFormHelperText={() => <></>}
          />
          <FormHelperText pb='2'>Deposit Interval</FormHelperText>
          <OptionInputGroup
            options={[
              SavingPlanInterval.Daily,
              SavingPlanInterval.Weekly,
              SavingPlanInterval.Monthly,
            ]}
            selectedOption={interval}
            setSelectedOption={(option) => {
              setInterval(option as unknown as SavingPlanInterval);
            }}
            getFormHelperText={() => <></>}
          />
        </FormControl>
        {getInfoText()}
      </Flex>
    );
  }, [deposit, interval, getInfoText]);

  const getModalActions = useCallback(() => {
    const cancel = (
      <Button onClick={onClose} variant='outline' colorScheme='red'>
        Cancel
      </Button>
    );

    if (plan && deposit) {
      return (
        <>
          <Button
            onClick={updateSavingPlan}
            variant='outline'
            colorScheme='teal'
          >
            Update Plan
          </Button>
          {cancel}
        </>
      );
    }
    if (deposit) {
      return (
        <>
          <Button onClick={createSavingPlan} variant='solid' colorScheme='teal'>
            Create Plan
          </Button>
          {cancel}
        </>
      );
    }

    return cancel;
  }, [plan, deposit, updateSavingPlan, createSavingPlan, onClose]);

  const getModalFooter = useCallback(() => {
    return <ButtonGroup spacing='2'>{getModalActions()}</ButtonGroup>;
  }, [getModalActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={<Text>{plan ? 'Edit' : 'Create'} Savings Plan</Text>}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
