import { useEffect, useState } from 'react';
import { sendMessage, subscribeToMessages } from './workers';

interface Message {
  id: string;
  text: string;
  createdAt: Date;
  uid?: string;
  displayName?: string;
  chamaId: string;
}

export function useSendMessage(chamaId: string) {
  const [error, setError] = useState<Error | null>(null);

  const send = async (message: Omit<Message, 'id'>) => {
    try {
      await sendMessage(chamaId, message);
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  };

  return { send, error };
}

export function useMessages(chamaId: string) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = subscribeToMessages(chamaId, {
      next: (newMessages: Message[]) => setMessages(newMessages),
      error: (err: Error) => setError(err),
    });

    return () => unsubscribe();
  }, [chamaId]);

  return { messages, error };
}
